import React from 'react'
export default function Home(props) {
  React.useEffect(() => {
    //if(props.user) Router.push('')
  })
  return (
    <div>

    </div>
  )
}
